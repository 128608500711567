<template>
  <div class="bigbox">
    <div class="fil-box">
    <img src="https://qncdn.uni-sheep.com/s/Group%20996.png" alt="">
    <!-- <div class="fil-leftbox">
      <div class="fil-contentbox">
        <div class="fil-fir">
          <div class="fil-img">
            <img
              src="https://qncdn.uni-sheep.com/s/Mask%20group%20%2810%29%281%29.png"
              alt=""
            />
          </div>
          <div class="fil-g">
            <img
              src="https://qncdn.uni-sheep.com/s/Rectangle%20346241274%281%29.png"
              alt=""
            />
          </div>
          <div class="fil-tit family-title">联系我们</div>
        </div>
        <div class="fir-second family-title">我们将为您提供以下帮助，方便您了解自牧天成</div>
        <div class="fir-thre">
          <div class="fir-thtop">
            <div class="fir-thimg">
              <img
                src="https://qncdn.uni-sheep.com/s/Vector%20%2810%29%281%29.png"
                alt=""
              />
            </div>
            <div class="fir-thtit">
              详尽的产品功能和服务介绍，为您打造最专业的解决方案
            </div>
          </div>
          <div class="fir-thtop">
            <div class="fir-thimg">
              <img
                src="https://qncdn.uni-sheep.com/s/Vector%20%2810%29%281%29.png"
                alt=""
              />
            </div>
            <div class="fir-thtit">7*24小时在线产品顾问为您服务</div>
          </div>
        </div>
        <div class="fir-four">
          <div class="fir-fourtop">
            <div class="fir-fouimg">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20953%20%281%29%281%29.png"
                alt=""
              />
            </div>
            <div class="fir-phone">电话</div>
          </div>
          <div class="fir-foubottom">
            <div class="fir-fphone">
              <div class="fir-fouimg"></div>
              <div class="fir-phone">手机 : 180-4781-3330 </div>
            </div>
           
            <div class="fir-fphones">180-1921-1729</div>
          </div>
        </div>
        <div class="fir-five">
          <div class="fir-ftop">
            <div class="fir-fiimg">
              <img
                src="https://qncdn.uni-sheep.com/s/Vector%20%2811%29%281%29.png"
                alt=""
              />
            </div>
            <div class="fir-tit">微信公众号</div>
          </div>
          <div class="fir-fivbottom">
            <img
              src="https://qncdn.uni-sheep.com/s/qrcode_for_gh_b3dc8a02a90f_258%201%281%29.png"
              alt=""
            />
          </div>
        </div>
        <div class="fir-sexbox">
          <div class="fir-sextop">
            <div class="fir-seximg">
              <img
                src="https://qncdn.uni-sheep.com/s/Vector%20%2812%29%281%29.png"
                alt=""
              />
            </div>
            <div class="fir-sex">邮箱</div>
          </div>
          <div class="fir-sextop">
            <div class="fir-seximg">
            
            </div>
            <div class="fir-sex">nmgzmtc2023@163.com</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fil-rightbox">
      <div class="fil-contentbox">
        <div class="fir-rifir family-title">
          请填写真实信息，我们会在1-2个工作日内电话与您联系。
        </div>
        <div class="fincontent">
          <el-form
            :model="userValidateForm"
            ref="userValidateForm"
            class="demo-ruleForm"
            :rules="rules" 
          >
            <el-form-item label="姓名：" prop="username">
              <el-input
                v-model="userValidateForm.username"
                style="width: 29rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话：" prop="userphone">
              <el-input
                v-model="userValidateForm.userphone"
                style="width: 29rem"
              ></el-input>
            </el-form-item>
            <el-form-item label="机构类型：" prop="usertype">
              <el-select
                v-model="userValidateForm.usertype"
                placeholder="请选择机构类型"
                style="width: 29rem"
              >
                <el-option label="牧场机构" value="牧场机构"></el-option>
                <el-option label="运营商机构" value="运营商机构"></el-option>
                <el-option label="政府机构" value="政府机构"></el-option>
                <el-option label="金融机构" value="金融机构"></el-option>
                <el-option label="保险机构" value="保险机构"></el-option>
                <el-option label="其他机构" value="其他机构"></el-option>
              </el-select>
             
            </el-form-item>
            <el-form-item label="需求描述：" prop="userdescribe">
              <el-input
                class="stretchNone"
                :rows="7"
                type="textarea"
                show-word-limit
                maxlength="60"
                v-model="userValidateForm.userdescribe"
                style="width: 29rem"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
       
        <div class="fin-anniu">
          <el-button type="primary" @click="submitForm('userValidateForm')"
            >马上预约</el-button
          >
        </div>
      </div>
    </div> -->
  </div>
  </div>

</template>
<script>


export default {
  props: [],
  data() {
    return {
      rules: {
        username: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
          
        ],
        userphone: [
          { required: true, message: "电话不能为空", trigger: "blur" },
        ],
        usertype: [
          { required: true, message: "类型不能为空", trigger: "change" },
        ],
        userdescribe: [
          { required: true, message: "描述不能为空", trigger: "blur" },
        ],
      },
      userValidateForm: {
        username: "",
        userphone: "",
        usertype: "",
        userdescribe: "",
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
          console.log(this.userValidateForm);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {
    this.$emit("public_header", false);
  },
  beforeMounte() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDistroy() {},
  distroyed() {},
};
</script>
<style scoped lang="scss">
.bigbox{
  width: 100%;
  // height: 100%;
 
}
.fil-box {
  // width: 69.875rem;
  // height: 48.9375rem;
  width:79rem;
  height:36rem;
  // border: 1px solid #000;
  // background:url('https://qncdn.uni-sheep.com/s/Group%20996.png') center center;
  // background-repeat: no-repeat;
  // background-size: cover;
  margin: 0 auto;
  margin-top: 5rem;
  
  left:0;
  margin-top: 8rem;
  display: flex;
  img{
    width: 100%;
    height: 100%;
  }
  .fil-leftbox {
    width: 50%;
    height: 100%;
    // border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    .fil-contentbox {
      width: 90%;
      height: 90%;
      // border: 1px solid #000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .fil-fir {
        width: 60%;
        height: 3.75rem;
        // border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .fil-img {
          width: 8.8125rem;
          height: 100%;
          // border: 1px solid #000;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .fil-g {
          width: 0.1875rem;
          height: 1.4375rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .fil-tit {
          font-size: 1.8rem;
          // font-weight: 800;
          color:#313848;
        }
      }
      .fir-second {
        width: 100%;
        height: 3.25rem;
        // border: 1px solid #000;
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        // font-weight: 800;
        color:#313848;
      }
      .fir-thre {
        width: 100%;
        height: 5rem;
        // border: 1px solid #000;
        display: flex;
        flex-direction: column;
        .fir-thtop {
          width: 100%;
          height: 50%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .fir-thimg {
            width: 1.125rem;
            height: 1.125rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .fir-thtit {
            font-size: 1rem;
            color: #313848;
            margin-left: 0.6rem;
          }
        }
        .fir-thbottom {
          width: 100%;
          height: 50%;
          // border: 1px solid #000;
        }
      }
      .fir-four {
        width: 100%;
        height: 5.5rem;
        // border: 1px solid #000;
        .fir-fourtop {
          width: 30%;
          height: 50%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .fir-fouimg {
            width: 1.4375rem;
            height: 1.4375rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .fir-phone {
            font-size: 1rem;
            color: #797f92;
            margin-left: 0.6rem;
          }
        }
        .fir-foubottom {
          width: 100%;
          height: 50%;
          // border: 1px solid #000;
          display: flex;

          .fir-fphone {
            width:42%;
            height: 100%;
            // border: 1px solid #000;
            font-size: 1rem;
            color: #797f92;

            display: flex;
            align-items: center;
            .fir-fouimg {
              width: 1.4375rem;
              height: 1.4375rem;
            }
            .fir-phone {
              font-size: 1rem;
              color: #797f92;
              margin-left: 0.6rem;
            }
          }
          .fir-fphones {
            width: 30%;
            height: 100%;
            // border: 1px solid #000;
            display: flex;
            align-items: center;
            font-size: 1rem;
            color: #797f92;
          }
        }
      }
      .fir-five {
        width: 100%;
        height: 15rem;
        // border: 1px solid #000;
        .fir-ftop {
          width: 100%;
          height: 24%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .fir-fiimg {
            width: 1.4375rem;
            height: 1.4375rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .fir-tit {
            font-size: 1rem;
            color: #797f92;
            margin-left: 0.6rem;
          }
        }
        .fir-fivbottom {
          width: 10.8125rem;
          height: 10.875rem;
          // border: 1px solid #000;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .fir-sexbox {
        width: 100%;
        height: 5.5rem;
        // border: 1px solid #000;
        .fir-sextop {
          width: 100%;
          height: 50%;
          // border: 1px solid #000;
          display: flex;
          align-items: center;
          .fir-seximg {
            width: 1.4375rem;
            height: 1.0625rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .fir-sex {
            font-size: 1rem;
            color: #797f92;
            margin-left: 0.6rem;
          }
        }
      }
    }
  }
  .fil-rightbox {
    width: 50%;
    height: 100%;
    // border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    .fil-contentbox {
      width: 90%;
      height: 90%;
      // border: 1px solid #000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .fir-rifir {
        width: 100%;
        height: 3rem;
        // border: 1px solid #000;
        font-size: 1.1rem;
        line-height: 3rem;
        // font-weight: 800;
        color:#313848;
      }
      .fincontent {
        width: 100%;
        height: 34.5rem;
        // border: 1px solid #000;
      }

     
      .fin-anniu {
        width: 100%;
        height: 3.5rem;
        // border: 1px solid #000;
       
        .el-button {
          width: 93%;
          height: 3.5rem;
          // border: 1px solid #000;
          font-size: 0.96rem;
        }
      }
    }
  }
}
::v-deep .el-textarea__inner {
  resize: none;
}
</style>
